<template>
  <UiModalContainer
    footer-classes="flex justify-end"
    :modal-show="modal"
    :modal-width="40"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-i18n="attendanceTranslation">Subject Details</span>
    </template>

    <div
      v-for="(teacher, idx) in details.teachers"
      :key="idx"
      class="px-8"
      :class="[idx === details.teachers.length - 1 ? 'mb-8' : '', idx === 0 ? 'mt-8' : '']"
    >
      <div class="text-2xl text-text-color font-medium font-roboto">
        {{ teacher }}
      </div>
      <div class="flex md:flex-row flex-col mt-5 gap-7">
        <div class="flex flex-1 gap-5 flex-col">
          <div class="flex justify-start text-base text-label-text font-bold md:whitespace-nowrap">
            <template v-if="currentCampusScope && !currentSectionScope">
              <span v-i18n="attendanceTranslation">Campus</span>
              <span class="px-3 text-primary-grey-light font-normal">
                {{ details.title }}
              </span>
            </template>
            <template v-if="currentCampusScope && currentSectionScope">
              <span v-i18n="attendanceTranslation">Class</span>
              <span class="px-3 text-primary-grey-light font-normal">
                {{ details.credit_hours }}
              </span>
            </template>
          </div>
          <div class="flex justify-start text-base text-label-text font-bold">
            <span v-i18n="attendanceTranslation">Subject</span>
            <span class="px-3 text-primary-grey-light font-normal">
              {{ details.title }}
            </span>
          </div>
          <div class="flex justify-start text-base text-label-text font-bold">
            <span v-i18n="attendanceTranslation">Subject Code</span>
            <span class="pl-3 text-primary-grey-light font-normal">
              {{ details.course_code }}
            </span>
          </div>
        </div>
        <div class="flex gap-5 flex-1 flex-col text-left">
          <div class="flex items-center text-base text-label-text font-bold md:whitespace-nowrap">
            <span v-i18n="attendanceTranslation">Subject Color</span>
            <div
              class="mx-3 h-3 w-3 rounded-full"
              :style="{ backgroundColor: details.color }"
            ></div>
          </div>
          <div class="text-base text-label-text font-bold">
            <span v-i18n="attendanceTranslation">Credit Hours</span>
            <span class="px-3 text-primary-grey-light font-normal">
              {{ details.credit_hours }}
            </span>
          </div>
        </div>
      </div>
      <hr v-if="details.teachers.length > 1 && idx < details.teachers.length - 1" class="my-5" />
    </div>
  </UiModalContainer>
</template>

<script>
// import Icon from '@components/icons/icon.vue'
import { mapState } from 'vuex'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
export default {
  components: {
    // Icon,
    UiModalContainer,
  },
  props: {
    modal: { type: Boolean, default: false },
    details: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      attendanceTranslation: 'attendanceTranslation',
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
  },
  methods: {
    /**
     * Handle Click
     * @param {String} eventName
     * @returns {void}
     * @description Function Description :
     * - Handling click on the basis of parameter
     * - Emiting toggle to parent component
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'confirm':
          break
      }
    },
  },
}
</script>
