<template>
  <div>
    <SubjectsFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(subjectList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="
              showColumnInDataTable(filtersData, head.dataTableName, ['Title', 'Assigned Teachers'])
            "
            :key="head.id"
          >
            <span v-i18n="dashboard">
              {{ head.dataTableName }}
            </span>
          </TH>
          <TH v-permission="tableActionsPermissions" v-i18n="dashboard">Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(subject, index) in subjectList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
            {{ subject.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Concise Name')">
            {{ subject.course_code || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Color')">
            <span
              class="h-5 block rounded"
              :style="{
                backgroundColor: getEventColor(subject.color, 700),
              }"
            ></span>
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Assigned Teachers', ['Assigned Teachers'])"
          >
            <span v-if="subject.teachers.length > 0">
              <MultiUserDisplay
                :options="subject.teachers"
                label="full_name"
                :image="true"
                :dropdown-top-bottom="dropdownTopBottom(index, subjectList, 'bottom-6', 'top-6')"
                :tooltip="dropdownTopBottom(index, subjectList, 'tooltip-bottom', 'tooltip-top')"
              />
            </span>
            <span v-else>
              {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
            v-permission="tableActionsPermissions"
          >
            <TableAction
              :current-user="subject"
              :idx="index"
              :action-list="actionList"
              @action="subjectAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(subjectCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filterRecord"
    />
    <SubjectModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentSubject"
      @toggle="subjectModalToggle"
    />
    <EnrolTeacherModal
      v-if="showEnrolTeacherModal"
      :modal="showEnrolTeacherModal"
      :current-subject="currentSubject"
      @toggle="toggleEnrolment"
    />
    <SubjectDetails
      v-if="detailsModal"
      :modal="detailsModal"
      :details="details"
      @toggle="detailsModalToggle"
    />
  </div>
</template>

<script>
// Components
import SubjectsFilter from './SubjectsFilter.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import TableAction from '@/src/components/TableAction.vue'
import SubjectModal from '@/src/router/views/admin/modals/SubjectModal.vue'
import SubjectDetails from '@src/router/views/subjects/SubjectDetails.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import EnrolTeacherModal from '@src/router/views/admin/modals/EnrolTeacherModal.vue'
import MultiUserDisplay from '@src/components/UiElements/MultiUserDisplay.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'

import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants.js'
import fileMixins from '@/src/mixins/file-mixins'
import subjectData from '@/src/router/views/subjects/subjects.json'
import isEmpty from 'lodash/isEmpty'
import { mapActions, mapState } from 'vuex'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { objectDeepCopy, showColumnInDataTable, dropdownTopBottom } from '@src/utils/generalUtil'
import { getEventColor } from '@src/utils/timeTableUtils.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import {
  paginationCounts,
  paginationRangeHandler,
} from '@src/components/BaseComponent/pagination.util.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'Subjects',
  components: {
    SubjectDetails,
    SubjectsFilter,
    Loader,
    TableAction,
    SubjectModal,
    Pagination,
    EnrolTeacherModal,
    MultiUserDisplay,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [fileMixins, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      dashboard: 'dashboard',
      tableActionsPermissions: {
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
        roles: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },
      tableHeaders: subjectData.tableHeaders,
      defaultSelectedColumns: subjectData.defaultSelectedColumns,
      filtersData: {},
      subjectList: [],
      isLoading: false,
      noRecord: false,
      actionList: [{ name: 'Assign Teacher' }, { name: 'Edit' }],
      currentSubject: null,
      showPagination: false,
      filteredRecordLimit: 10,
      subjectCounts: 0,
      showEnrolTeacherModal: false,
      detailsModal: false,
      details: null,
    }
  },
  page: {
    title: 'Syllabus | Subject',
    meta: [
      {
        name: 'description',
        content: 'Manage  Syllabus of each Subject ',
      },
    ],
  },
  computed: {
    ...mapState({
      campus: (state) => state.layout.currentCampusScope,
      class: (state) => state.layout.currentSectionScope,
      showModal: (state) => state.layout.showModal,
      tabRightBar: (state) => state.layout.tabRightNav,
    }),
    isCampusOrSectionChange() {
      return this.campus + this.class
    },
  },

  watch: {
    isCampusOrSectionChange: {
      handler() {
        this.setRightbar()
        this.filterRecord()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchSubjectStats()
      },
    },
  },

  /**
   * Mounted Hook
   * @description Filters the record
   */
  mounted() {
    this.filterRecord()
    this.setRightbar()
  },
  methods: {
    getEventColor,
    isEmpty,
    dropdownTopBottom,
    showColumnInDataTable,
    paginationCounts,
    paginationRangeHandler,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    /**
     * Subject Modal Toggle
     * @param {boolean} payload
     * @returns {void}
     * @description Toggling the subject modal and filters the record
     */
    subjectModalToggle(payload = false) {
      this.setShowModal()
      if (payload) {
        this.filterRecord()
      }
      if (!this.showModal) this.currentSubject = null
    },
    /**
     * Toggle Enrolment
     * @param {boolean} payload
     * @returns {void}
     * @description Toggling the  showEnrolTeacherModal's value and filters the record
     */
    toggleEnrolment(payload) {
      this.showEnrolTeacherModal = !this.showEnrolTeacherModal
      if (!this.showEnrolTeacherModal) this.currentSubject = null
      if (payload) this.filterRecord()
    },
    /**
     * Filter Record
     * @param {Number} range
     * @returns {void}
     * @description filtering the record on the basis of range
     */
    filterRecord(range) {
      this.isLoading = true

      let payload = paginationRangeHandler(range)
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload

      this.classSubjects(payload)
        .then((res) => {
          this.subjectList = res?.data?.records || []
          this.subjectList.forEach((subject) => {
            if (subject.teachers.length) {
              subject.teachers.forEach((teacher) => fullName(teacher))
            }
          })
          this.subjectCounts = res?.data?.meta?.total_records
          this.showPagination = this.subjectCounts > GENERAL_CONSTANTS.RECORD_LIMIT
        })
        .finally(() => {
          this.fetchSubjectStats()
          this.noRecord = isEmpty(this.subjectList)
          this.isLoading = false
        })
    },
    fetchSubjectStats(payload = { $where: this.filtersData?.$where }) {
      this.getSubjectStats(payload).then((res) => {
        this.setRightbar(res)
      })
    },
    /**
     * Set RightBar
     * @param {object} list
     * @param {number} count
     * @returns {void}
     * @description Sets the RightBar'content
     */

    setRightbar(subject) {
      this.setRightbarContent({
        header: {
          title: 'Subjects',
          buttons: [
            {
              title: 'Add Subjects',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
              permissions: {
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.CAMPUS_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                ],
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: `Total Subject`,
                value: subject?.subjects || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total No of Subjects',
              },
              {
                text: `SUBJECT_ASSIGNED_TEACHER`,
                value: subject?.subjects_assigned_to_teacher || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total No of Subjects Assigned to Teachers',
              },
            ],
          },
        ],
      })
    },
    /**
     * Subject Action
     * @param {String} action
     * @param {String} current
     * @returns {void}
     * @description Performing Assign teacher , Edit and delete actions on subject
     */
    subjectAction(action, current) {
      this.currentSubject = current
      switch (action) {
        case 'Assign Teacher':
          this.toggleEnrolment()
          break
        case 'Edit':
          this.subjectModalToggle()
          break
        case 'Delete':
          this.deleteSubject(this.currentSubject.id)
            .then(() => {
              this.filterRecord()
            })
            .finally(() => {
              this.currentSubject = null
            })

          break
      }
    },
    /**
     * Details Modal Toggle
     * @param {String} subject
     * @returns {void}
     * @description Toggling the detailsModal's value
     */
    detailsModalToggle(subject) {
      if (subject) this.details = subject
      this.detailsModal = !this.detailsModal
    },

    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('subjects', ['classSubjects', 'getSubjectStats', 'deleteSubject']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
