<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="SUBJS"
      tooltip-title="SUBJS"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper :layout-mode="isAdmin">
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="Title"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.course_code"
            class="flex-1"
            title="CONCISE_NAME"
            type="text"
            placeholder="CONCISE_NAME"
            :filter="{
              option: FILTER_KEYS.ILIKE,
              key: 'course_code',
            }"
            @emitQuery="updateQuery"
          />
          <UiMultiSelectBox
            v-if="isAdmin"
            v-model="filtersPayload.assigned_teachers_ids"
            :options="teacherList"
            title="Teachers"
            label="full_name"
            class="flex-1"
            :already-selected="selectedTeachers"
            :filter="{
              option: FILTER_KEYS.IN,
              key: 'assigned_teachers_ids',
            }"
            reduce="id"
            :image="true"
            input-color="bg-white"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="loadteacherList"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import UiMultiSelectBox from '@src/components/UiElements/UiMultiSelect.vue'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import fileMixins from '@/src/mixins/file-mixins'
import filterMixins from '@/src/mixins/filter-mixins.js'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    TitleWrapper,
    UiMultiSelectBox,
    InputFieldWrapper,
  },
  mixins: [fileMixins, filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILTER_KEYS,
      FILTERS_LIST,
      $where: {},
      queries: {},
      appliedQueries: {},
      teacherList: [],
      title: 'title',
      filtersCount: 0,
      selectedTeachers: [],
      filtersPayload: {
        title: '',
        course_code: '',
        assigned_teachers_ids: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    changeCampusOrSection() {
      return this.campus + this.currentSectionScope
    },
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      campus: (state) => state.layout.currentCampusScope || null,
    }),
    isAdmin() {
      return [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN].includes(this.activeRole)
    },
  },
  watch: {
    changeCampusOrSection: {
      handler() {
        this.clearFilters()
        this.loadteacherList()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.loadteacherList()
  },

  methods: {
    loadteacherList(query = '') {
      if (!this.isAdmin) return
      let alreadySelectedTeachers = []
      const payload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) } }
      payload.$where = {
        ...payload.$where,
        ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.currentSectionScope?.id),
        ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', this.campus?.id),
      }
      removeEmptyKeysFromObject(payload.$where)
      this.getTeacherWithFilters(payload).then((response) => {
        this.teacherList = response?.data?.records
        if (response)
          this.teacherList.forEach((teacher) => {
            fullName(teacher)
            if (this.filtersPayload && this.filtersPayload.assigned_teachers_ids.length) {
              this.filtersPayload.assigned_teachers_ids.forEach((id) => {
                if (teacher.id === id) alreadySelectedTeachers.push(teacher)
              })
            }
            this.selectedTeachers = alreadySelectedTeachers
          })
      })
    },
    getFiltersCount() {
      this.loadteacherList()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('teacher', ['getTeacherWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
